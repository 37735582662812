import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from '@theme-ui/components';
export const _frontmatter = {
  "date": "2020-04-09T00:00:00.000Z",
  "title": "Journey with Vue-Router by Laurie Barth",
  "published": true
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Box variant="planningSession" {...props}>
    {props.header}
    {children}
  </Box>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41.73913043478261%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'167\\'%20viewBox=\\'0%200%20400%20167\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M315%2019c-1%205%200%206%208%207h8v-8h-8l-8%201M149%20157v5h51c55%200%2052%200%2052-5%200-6%203-6-52-6h-51v6\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Laurie Barth Planning Session",
          "title": "Laurie Barth Planning Session",
          "src": "/static/c094b0147c994acd72b0b7b6cfd47bc1/c0255/laurie-barth-planning-session.png",
          "srcSet": ["/static/c094b0147c994acd72b0b7b6cfd47bc1/81c8e/laurie-barth-planning-session.png 230w", "/static/c094b0147c994acd72b0b7b6cfd47bc1/08a84/laurie-barth-planning-session.png 460w", "/static/c094b0147c994acd72b0b7b6cfd47bc1/c0255/laurie-barth-planning-session.png 920w", "/static/c094b0147c994acd72b0b7b6cfd47bc1/b1001/laurie-barth-planning-session.png 1380w", "/static/c094b0147c994acd72b0b7b6cfd47bc1/161ec/laurie-barth-planning-session.png 1840w", "/static/c094b0147c994acd72b0b7b6cfd47bc1/60708/laurie-barth-planning-session.png 2872w"],
          "sizes": "(max-width: 920px) 100vw, 920px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com/sh/vo719goa0mddu1l/AABGSsP-ovmkTfPGe6UsPYOIa?dl=0"
      }}>{`▶️ Session Recording Recording (6/9/2019)`}</a></p>
    <p>{`This is an example where the initial submission was somewhat sparse, but we ended up fleshing it out well during the proposal session.`}</p>
    <h4 {...{
      "id": "original-proposal-submission"
    }}>{`Original Proposal Submission`}</h4>
    <p><strong parentName="p">{`Why is this topic important for somebody to learn?`}</strong></p>
    <p>{`Routers are powerful and an essential part of any application. Learning how to use the vue-router is important for anyone building a vue application.`}</p>
    <p><strong parentName="p">{`What should the learner be able to do at the end of the course?`}</strong></p>
    <p>{`Use vue-router and all of its built in functionality in an application.`}</p>
    <p><strong parentName="p">{`What are the milestones that a student will reach as they work toward the big picture goal of this workshop?`}</strong></p>
    <p>{`Create a basic router, create sub-routes, create wildcard path routes, create an interceptor for routes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      