import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from '@theme-ui/components';
export const _frontmatter = {
  "date": "2020-04-09T00:00:00.000Z",
  "title": "Build an App with React Suspense",
  "published": true
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Box variant="planningSession" {...props}>
    {props.header}
    {children}
  </Box>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.173913043478265%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'168\\'%20viewBox=\\'0%200%20400%20168\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M1%201L0%202c0%202%20400%201%20401-1C401%200%202-1%201%201m324%2018c-1%204%200%205%208%206h7v-7h-7l-8%201M124%20166l-63%201%20139%201a4231%204231%200%200066-2l-5-1-6%201-1%201c-1-2-4-2-5-1h-1l-1-1-2%201h-12l-3-1-3%201h-10l-3-1-3%201h-23l-1%201c-1-2-4-2-5-1h-13c0-1-5-1-8%201l-2-1-4-1c-3%200-4%200-3%201l-1%201-2-1h-25\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Build an App with React Suspense Planning Session",
          "title": "Build an App with React Suspense Planning Session",
          "src": "/static/141b1f0334db6b179fc2a3411408ad64/c0255/build-an-app-with-react-suspense-planning-session.png",
          "srcSet": ["/static/141b1f0334db6b179fc2a3411408ad64/81c8e/build-an-app-with-react-suspense-planning-session.png 230w", "/static/141b1f0334db6b179fc2a3411408ad64/08a84/build-an-app-with-react-suspense-planning-session.png 460w", "/static/141b1f0334db6b179fc2a3411408ad64/c0255/build-an-app-with-react-suspense-planning-session.png 920w", "/static/141b1f0334db6b179fc2a3411408ad64/b1001/build-an-app-with-react-suspense-planning-session.png 1380w", "/static/141b1f0334db6b179fc2a3411408ad64/161ec/build-an-app-with-react-suspense-planning-session.png 1840w", "/static/141b1f0334db6b179fc2a3411408ad64/60708/build-an-app-with-react-suspense-planning-session.png 2872w"],
          "sizes": "(max-width: 920px) 100vw, 920px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com/sh/ujg0s5wsuigm8ng/AADIG-sznut9nyzMb4raY_0Ya?dl=0"
      }}>{`▶️ Session Recording Recording (11/9/2019)`}</a></p>
    <p><strong parentName="p">{`Why is this topic important for somebody to learn?`}</strong></p>
    <p>{`React changed a lot last year with Hooks. It's poised to changed again this year with Suspense. This course will show folks how easy it is to build an API-backed application with Suspense, Hooks, and Context. Suspense is in production builds of React now but the react team hasn't provided a model cache. It's on the roadmap for fall 2019 and likely the primary focus of React Conf 2019.`}</p>
    <p><strong parentName="p">{`What should the learner be able to do at the end of the course?`}</strong></p>
    <p>{`Build an application that fetches and posts data from a RESTful API using the latest React APIs: Suspens, Hooks, and Context.`}</p>
    <p><strong parentName="p">{`What are the milestones that a student will reach as they work toward the big picture goal of this workshop?`}</strong></p>
    <ul>
      <li parentName="ul">{`Build a data-less prototype`}</li>
      <li parentName="ul">{`Create Resources for collecting and interacting with data`}</li>
      <li parentName="ul">{`Fetch data from a RESTful API`}</li>
      <li parentName="ul">{`Orchestrate complex loading states with the Suspense component`}</li>
      <li parentName="ul">{`Create Resource-reading components`}</li>
      <li parentName="ul">{`Manage and clear a React Cache`}</li>
      <li parentName="ul">{`Post data to a RESTFUL API`}</li>
      <li parentName="ul">{`Organize an application with Resources, Hooks, Resources-reading components, and UI components.`}</li>
    </ul>
    <p><strong parentName="p">{`What will your example or demo be for this workshop?`}</strong></p>
    <p>{`I created a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/playlist?list=PLnc_NxpmOxaPbyq_lEGZKWF7R3BbK3PSZ"
      }}>{`YouTube series on this content`}</a>{` in 2018, when I thought React Cache would be coming in spring. It's a 27 video series where we build a (paired down) Pokedex concept-by-concept. The application specifics could be changed from Pokemon to something else. But the application could structure could be largely the same. This could be GitHub repositories or some other type of silly resource.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      