import DesigningGraphqlSchemasByNikGraf from "../../../../content/instructor/workshops/planning-process/designing-graphql-schemas-by-nik-graf";
import FixCommonGitMistakesByChrisAchard from "../../../../content/instructor/workshops/planning-process/fix-common-git-mistakes-by-chris-achard";
import JourneyWithVueRouterByLaurieBarth from "../../../../content/instructor/workshops/planning-process/journey-with-vue-router-by-laurie-barth";
import ComposableGatsbyThemesByChrisBiscardi from "../../../../content/instructor/workshops/planning-process/composable-gatsby-themes-by-chris-biscardi";
import BuildingUisWithXstateStateMachinesByIsaacMann from "../../../../content/instructor/workshops/planning-process/building-uis-with-xstate-state-machines-by-isaac-mann";
import BuildAnAppWithReactSuspenseByMichaelChan from "../../../../content/instructor/workshops/planning-process/build-an-app-with-react-suspense-by-michael-chan";
import * as React from 'react';
export default {
  DesigningGraphqlSchemasByNikGraf,
  FixCommonGitMistakesByChrisAchard,
  JourneyWithVueRouterByLaurieBarth,
  ComposableGatsbyThemesByChrisBiscardi,
  BuildingUisWithXstateStateMachinesByIsaacMann,
  BuildAnAppWithReactSuspenseByMichaelChan,
  React
};