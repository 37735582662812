import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from '@theme-ui/components';
export const _frontmatter = {
  "date": "2020-04-09T00:00:00.000Z",
  "title": "Designing GraphQL Schemas by Nik Graf",
  "published": true
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Box variant="planningSession" {...props}>
    {props.header}
    {children}
  </Box>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.173913043478265%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'168\\'%20viewBox=\\'0%200%20400%20168\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M363%2011c0%2010%200%2010%202%2010h35V0h-37v11M115%2024v3h15l1-4v-3h-16v4m200-1l1%204h15v-7h-16v3M48%20153v6l1%204h102v-10l-51-1-52%201m201%204l1%206h101v-11H249v5\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Designing GraphQL Schemas by Nik Graf",
          "title": "Designing GraphQL Schemas by Nik Graf",
          "src": "/static/ed6d9cf45edba62e08261b63a27b112f/c0255/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png",
          "srcSet": ["/static/ed6d9cf45edba62e08261b63a27b112f/81c8e/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png 230w", "/static/ed6d9cf45edba62e08261b63a27b112f/08a84/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png 460w", "/static/ed6d9cf45edba62e08261b63a27b112f/c0255/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png 920w", "/static/ed6d9cf45edba62e08261b63a27b112f/b1001/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png 1380w", "/static/ed6d9cf45edba62e08261b63a27b112f/161ec/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png 1840w", "/static/ed6d9cf45edba62e08261b63a27b112f/93ba6/designing-graphql-schemas-that-stand-the-test-of-time-planning-session.png 2874w"],
          "sizes": "(max-width: 920px) 100vw, 920px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com/s/27y7rqeug7aj4p9/Nik_Graf_GraphQL_Schema_proposal.mp4?dl=0"
      }}>{`▶️ Session Recording (18/9/2019)`}</a></p>
    <p><strong parentName="p">{`Why is this topic important for somebody to learn?`}</strong></p>
    <p>{`GraphQL is getting more and more adoption and onboarding is covered by many resources. When people start to adopt it for production apps they start to struggle with the API design. GraphQL is even more flexible than REST and it’s easy to build an API where it’s hard to move forward.`}</p>
    <p><strong parentName="p">{`What should the learner be able to do at the end of the course?`}</strong></p>
    <p>{`Students should feel comfortable designing a GraphQL API based on best practices like the Connection spec. They should have a strong understanding about designing mutations and the related error handling.`}</p>
    <p><strong parentName="p">{`What are the milestones that a student will reach as they work toward the big picture goal of this workshop?`}</strong></p>
    <ol>
      <li parentName="ol">{`Naming of queries`}</li>
      <li parentName="ol">{`Naming of query properties`}</li>
      <li parentName="ol">{`IDs vs full objects for connected entities in queries`}</li>
      <li parentName="ol">{`Curser vs pages for pagination in queries`}</li>
      <li parentName="ol">{`Edges in the GraphQL Connection spec`}</li>
      <li parentName="ol">{`Multiple arguments vs object input for Mutations`}</li>
      <li parentName="ol">{`Handling expected Mutation errors`}</li>
      <li parentName="ol">{`Handling unexpected Mutation & Query errors`}</li>
    </ol>
    <p><strong parentName="p">{`What will your example or demo be for this workshop?`}</strong></p>
    <p>{`Either a location guide or a web shop could be great examples. Both can start simple and it’s clear how they can expand to cover more complex cases. This I believe is very important for explaining advanced design concepts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      