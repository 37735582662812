import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Review 101",
  "slug": "reviewer/overview/review-101",
  "guide": "reviewer",
  "chapterTitle": "Overview"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    {children}
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "review-101"
    }}>{`Review 101`}</h1>
    <p>{`Video training content goes stale. egghead.io should be as up to date as possible when it comes to content. Because of the nature of video we need to monitor each lesson on multiple levels.`}</p>
    <br />
    <h2 {...{
      "id": "lesson-video"
    }}>{`Lesson Video`}</h2>
    <p>{`An excellent coding screencast will focus on core concepts and have the ability to remain “evergreen” through minor revisions of the library that it is demonstrating. We need to continue to monitor this to make sure that it is the case.`}</p>
    <p>{`When differences exist we can take several courses of action:`}</p>
    <ul>
      <li parentName="ul">{`update the code sample and use comments in the code to notify the user that it has changed from the video`}</li>
      <li parentName="ul">{`Annotate the lesson’s page to tell the student what is wrong with the lesson`}</li>
      <li parentName="ul">{`Replace the lesson video/code with an updated version`}</li>
      <li parentName="ul">{`mark the lesson as obsolete/deprecated`}</li>
      <li parentName="ul">{`record a new version (vs replacement)`}</li>
    </ul>
    <br />
    <h3 {...{
      "id": "update-lesson-code-sample"
    }}>{`Update Lesson Code Sample`}</h3>
    <p>{`This is the most common action taken by the reviewer. The lesson code sample should reflect the current version(s) for the libraries it is demonstrating (libraries are updated often!). The sample code can correct for minor differences in the code demonstrated in the lesson video, noting with comments when this has been done.`}</p>
    <h3 {...{
      "id": "annotate-the-lesson-page"
    }}>{`Annotate the lesson page`}</h3>
    <p>{`When there is some significant breaking difference in the lesson video and code sample, we can create a notification to be displayed prominently on the lesson page. This notification comes in the form of an `}<inlineCode parentName="p">{`errata`}</inlineCode>{` which is shown below:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.52173913043479%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'226\\'%20viewBox=\\'0%200%20400%20226\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M13%2010v28l1%2027h282l2%202%202%202c0-4%202-4%207-2%207%204%209%206%207%207-2%203-1%206%202%206%204%200%205-4%202-5l-1-1%2033%2017a559%20559%200%20009%202c-19-10-26-14-25-15%202-2%200-3-3-2-2%201-4%200-13-5l-10-5%2040-1h41l1-2V10L202%209%2013%2010m4%2083c-3%202-4%206-3%209l2%202%201-3v-3l2-2c2%200%202%200%201%201v4c-2%202-2%202-1%203%206%204%2011-4%206-10-3-2-5-3-8-1\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Errata Example",
          "title": "Errata Example",
          "src": "/static/bf6d5d781c90bbacebfa3da4cac941a7/c0255/00-errata-example.png",
          "srcSet": ["/static/bf6d5d781c90bbacebfa3da4cac941a7/81c8e/00-errata-example.png 230w", "/static/bf6d5d781c90bbacebfa3da4cac941a7/08a84/00-errata-example.png 460w", "/static/bf6d5d781c90bbacebfa3da4cac941a7/c0255/00-errata-example.png 920w", "/static/bf6d5d781c90bbacebfa3da4cac941a7/38124/00-errata-example.png 953w"],
          "sizes": "(max-width: 920px) 100vw, 920px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "replace-the-lesson-videocode"
    }}>{`Replace the lesson video/code`}</h3>
    <p>{`If the lesson still has significant value conceptually, we can re-record the lesson and produce an updated code sample. We want to do this for popular lessons and maintain our SEO.`}</p>
    <p>{`Make a note of why you think the lesson needs to be re-recorded in the review document.`}</p>
    <h3 {...{
      "id": "mark-as-obsolete"
    }}>{`Mark as obsolete`}</h3>
    <p>{`Like Annotation above, but `}<strong parentName="p">{`more deadly`}</strong>{`! :skull:`}</p>
    <h3 {...{
      "id": "record-a-new-lesson"
    }}>{`Record a new lesson`}</h3>
    <p>{`Sometimes we will want to record a new lesson that presents the underlying concept with the updated version instead of replacing it in-place`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      