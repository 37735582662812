import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from '@theme-ui/components';
export const _frontmatter = {
  "date": "2020-04-09T00:00:00.000Z",
  "title": "Fix Common Git Mistakes by Chris Achard",
  "published": true
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Box variant="planningSession" {...props}>
    {props.header}
    {children}
  </Box>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41.73913043478261%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'167\\'%20viewBox=\\'0%200%20400%20167\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M315%2019c-1%205%200%206%208%207h8v-8h-8l-8%201M149%20155c-1%207-5%207%2051%207%2055%200%2052%200%2052-7v-3l-52-1h-51v4\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chris Achard Planning Session",
          "title": "Chris Achard Planning Session",
          "src": "/static/1ffd216993552e744d16c8f9f9e302d8/c0255/fix-common-git-mistakes-planning-session.png",
          "srcSet": ["/static/1ffd216993552e744d16c8f9f9e302d8/81c8e/fix-common-git-mistakes-planning-session.png 230w", "/static/1ffd216993552e744d16c8f9f9e302d8/08a84/fix-common-git-mistakes-planning-session.png 460w", "/static/1ffd216993552e744d16c8f9f9e302d8/c0255/fix-common-git-mistakes-planning-session.png 920w", "/static/1ffd216993552e744d16c8f9f9e302d8/b1001/fix-common-git-mistakes-planning-session.png 1380w", "/static/1ffd216993552e744d16c8f9f9e302d8/161ec/fix-common-git-mistakes-planning-session.png 1840w", "/static/1ffd216993552e744d16c8f9f9e302d8/60708/fix-common-git-mistakes-planning-session.png 2872w"],
          "sizes": "(max-width: 920px) 100vw, 920px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com/sh/xr52l23zugwnz6w/AAD9AwbSHU8qZvrpFvMbIC6Va?dl=0"
      }}>{`▶️ Session Recording Recording (13/9/2019)`}</a></p>
    <p><strong parentName="p">{`Why is this topic important for somebody to learn?`}</strong></p>
    <p>{`Programmers use git all day, everyday, but it's still common to get stuck into situations and have no idea how to get out without a lot of hassle. When you really know the git workflow well, it can speed up development, and reduce the hassle of getting stuck in a bad state.`}</p>
    <p><strong parentName="p">{`What should the learner be able to do at the end of the course?`}</strong></p>
    <p>{`Use git confidently! Or at least: recognize when they're in a situation that they can get out of gracefully.`}</p>
    <p><strong parentName="p">{`What are the milestones that a student will reach as they work toward the big picture goal of this workshop?`}</strong></p>
    <ol>
      <li parentName="ol">{`Understand how files are stored, cached, and pushed`}</li>
      <li parentName="ol">{`How to do and undo "add", "stash", "commit", "push"`}</li>
      <li parentName="ol">{`What to do when there are conflicts in merges`}</li>
      <li parentName="ol">{`How to switch branches gracefully`}</li>
      <li parentName="ol">{`Know what operations are destructive, and know how to not lose a bunch of work`}</li>
      <li parentName="ol">{`Handle gitignore properly, including ignoring files you've already commited`}</li>
      <li parentName="ol">{`Understand what origin is, and how to handle complicated server situations`}</li>
      <li parentName="ol">{`Merge vs rebase (and other workflow considerations)`}</li>
    </ol>
    <p><strong parentName="p">{`What will your example or demo be for this workshop?`}</strong></p>
    <p>{`I imagine it would be like a "cookbook" workshop, or a bunch of mini-lessons. "Here's how you could mess it up; now here's how to fix it". Perhaps there would also need to be an intro part to explain the git file/branch model as well, just so everyone is on the same page`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      