import John from "../../../../content/instructor/case-studies/john-lindquist/john.jpg";
import Kent from "../../../../content/instructor/case-studies/kent-c-dodds/kent.png";
import Laurie from "../../../../content/instructor/case-studies/laurie-barth/laurie.png";
import Eve from "../../../../content/instructor/case-studies/eve-porcello/eve.png";
import Nick from "../../../../content/instructor/case-studies/nick-dejesus/nick.png";
import Erin from "../../../../content/instructor/case-studies/erin-doyle/images/erin-doyle_800x800.png";
import Tomasz from "../../../../content/instructor/case-studies/tomasz-lakomy/images/Tomasz_Lakomy.jpg";
import Joe from "../../../../content/instructor/case-studies/joe-previte/images/joe-previte.png";
import Chris from "../../../../content/instructor/case-studies/chris-biscardi/images/chris-biscardi.jpeg";
import Will from "../../../../content/instructor/case-studies/will-johnson/images/will.jpeg";
import Maggie from "../../../../content/instructor/case-studies/maggie-appleton/images/maggie.png";
import Ceora from "../../../../content/instructor/case-studies/ceora-ford/images/ceora-ford.jpg";
import Mahmoud from "../../../../content/instructor/case-studies/mahmoud-abdelwahab/images/mahmoud.jpg";
import * as React from 'react';
export default {
  John,
  Kent,
  Laurie,
  Eve,
  Nick,
  Erin,
  Tomasz,
  Joe,
  Chris,
  Will,
  Maggie,
  Ceora,
  Mahmoud,
  React
};