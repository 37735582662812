import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import John from './john-lindquist/john.jpg';
import Kent from './kent-c-dodds/kent.png';
import Laurie from './laurie-barth/laurie.png';
import Eve from './eve-porcello/eve.png';
import Nick from './nick-dejesus/nick.png';
import Erin from './erin-doyle/images/erin-doyle_800x800.png';
import Tomasz from './tomasz-lakomy/images/Tomasz_Lakomy.jpg';
import Joe from './joe-previte/images/joe-previte.png';
import Chris from './chris-biscardi/images/chris-biscardi.jpeg';
import Will from './will-johnson/images/will.jpeg';
import Maggie from './maggie-appleton/images/maggie.png';
import Ceora from './ceora-ford/images/ceora-ford.jpg';
import Mahmoud from './mahmoud-abdelwahab/images/mahmoud.jpg';
export const _frontmatter = {
  "date": "2019-11-15T00:00:00.000Z",
  "title": "egghead instructor case studies",
  "description": "These are stories about egghead instructors and their exprience teaching on egghead.",
  "categories": ["instructor", "case study"],
  "published": true,
  "shareImage": "https://res.cloudinary.com/dg3gyk0gu/image/upload/v1600099940/og-image-assets/case-studies.png"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <div {...props}>{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Grid gap={0} columns={props.columns || 2} mdxType="Grid">
      <Card variant="primary" href="/instructor/case-studies/john-lindquist" mdxType="Card">
        <img src={John} />
        <div>
          <h2 {...{
            "id": "john-lindquist"
          }}>{`John Lindquist`}</h2>
          <p>{`The Original egghead 🥚`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/kent-c-dodds" mdxType="Card">
        <img src={Kent} />
        <div>
          <h2 {...{
            "id": "kent-c-dodds"
          }}>{`Kent C. Dodds`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/laurie-barth" mdxType="Card">
        <img src={Laurie} />
        <div>
          <h2 {...{
            "id": "laurie-barth"
          }}>{`Laurie Barth`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/eve-porcello" mdxType="Card">
        <img src={Eve} />
        <div>
          <h2 {...{
            "id": "eve-porcello"
          }}>{`Eve Porcello`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/nick-dejesus" mdxType="Card">
        <img src={Nick} />
        <div>
          <h2 {...{
            "id": "nick-dejesus"
          }}>{`Nick DeJesus`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/erin-doyle" mdxType="Card">
        <img src={Erin} />
        <div>
          <h2 {...{
            "id": "erin-doyle"
          }}>{`Erin Doyle`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/tomasz-lakomy" mdxType="Card">
        <img src={Tomasz} />
        <div>
          <h2 {...{
            "id": "tomasz-łakomy"
          }}>{`Tomasz Łakomy`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/joe-previte" mdxType="Card">
        <img src={Joe} />
        <div>
          <h2 {...{
            "id": "joe-previte"
          }}>{`Joe Previte`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/chris-biscardi" mdxType="Card">
        <img src={Chris} />
        <div>
          <h2 {...{
            "id": "chris-biscardi"
          }}>{`Chris Biscardi`}</h2>
          <p>{`egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/will-johnson" mdxType="Card">
        <img src={Will} />
        <div>
          <h2 {...{
            "id": "will-johnson"
          }}>{`Will Johnson`}</h2>
          <p>{`egghead instructor & community engineer`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/maggie-appleton" mdxType="Card">
        <img src={Maggie} />
        <div>
          <h2 {...{
            "id": "maggie-appleton"
          }}>{`Maggie Appleton`}</h2>
          <p>{`illustrator & art director`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/ceora-ford" mdxType="Card">
        <img src={Ceora} />
        <div>
          <h2 {...{
            "id": "ceora-ford"
          }}>{`Ceora Ford`}</h2>
          <p>{`learner advocate & egghead instructor`}</p>
        </div>
      </Card>
      <Card variant="primary" href="/instructor/case-studies/mahmoud-abdelwahab" mdxType="Card">
        <img src={Mahmoud} />
        <div>
          <h2 {...{
            "id": "mahmoud-abdelwahab"
          }}>{`Mahmoud Abdelwahab`}</h2>
          <p>{`learner advocate & egghead instructor`}</p>
        </div>
      </Card>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      