import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Quick Install (Mac)",
  "slug": "quick-install-mac",
  "guide": "reviewer"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    {children}
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "quick-install-mac"
    }}>{`Quick Install (Mac)`}</h2>
    <p>{`Open up your terminal paste in the following and then press return to install Homebrew: `}<inlineCode parentName="p">{`/usr/bin/ruby -e "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/master/install)"`}</inlineCode></p>
    <p>{`Afterwards you're going to run each of the following in your terminal:`}</p>
    <ul>
      <li parentName="ul">{`Google Chrome: `}<inlineCode parentName="li">{`brew cask google-chrome`}</inlineCode></li>
      <li parentName="ul">{`Slack: `}<inlineCode parentName="li">{`brew cask install slack`}</inlineCode></li>
      <li parentName="ul">{`GitKraken: `}<inlineCode parentName="li">{`brew cask install gitkraken`}</inlineCode></li>
      <li parentName="ul">{`Visual Studio Code: `}<inlineCode parentName="li">{`brew cask install visual-studio-code`}</inlineCode></li>
      <li parentName="ul">{`Yarn: `}<inlineCode parentName="li">{`brew install yarn`}</inlineCode></li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      